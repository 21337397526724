export default {
  title: "Bienvenue à nouveau ! ",
  "type-select": "Vous êtes...",
  "having-trouble": "Mot de passe oublié ?",
  "keep-me-signed-in": "Je souhaite rester connecté",
  "dont-have-an-account": "Pas de compte ? ",
  type: {
    householder: "Propriétaire",
    installer: "Professionnel",
  },
  "admin-alert":
    "Ne renseignez jamais votre mot de passe ailleurs que sur projetsolaire.com. Nous ne vous demanderons jamais de communiquer votre mot de passe ailleurs que lors de votre connexion. ",
  errors: {
    "account-locked":
      "Vous avez essayé de vous connecter trop de fois sans succès. Pour des raisons de sécurité, votre compte est verrouillé pendant 15 minutes. Patientez un instant ou réinitialisez votre mot de passe si besoin",
  },
}
